const trips = [
 {
        id: 1,
        title: "Dhaka",
        oldPrice: 480,
        newPrice: 460,
        coverImg: "madrid.png",
        stats: {
            rating: 4.8
        },
        location: "Bangladesh",
    },
    {
        id: 2,
        title: "New York",
        oldPrice: 510,
        newPrice: 480,
        coverImg: "firenze.png",
        stats: {
            rating: 4.7,
        },
        location: "USA",
    },
    {
        id: 3,
        title: "Casablanca",
        oldPrice: 165,
        newPrice: 140,
        coverImg: "paris.png",
        stats: {
            rating: 4.8,
        },
        location: "Morocco",
    },
    {
        id: 4,
        title: "Dakar",
        oldPrice: 600,
        newPrice: 550,
        coverImg: "london.png",
        stats: {
            rating: 4.9,
        },
        location: "Senegal",
    },
    
]

const events = [ 
    {
        id: 1,
        city: "Portofino",
        country: "Italy",
        coverImg: "portofinoitaly.jpg",
        price: "5,42k",
        days: 10,
        stats: {
            rating: 4.9,
        },
    },
    {
        id: 1,
        city: "London",
        country: "UK",
        coverImg: "londoneye.jpg",
        price: "2,42k",
        days: "07",
        stats: {
            rating: 4.8,
        },
    },
    {
        id: 1,
        city: "Osaka",
        country: "Japan",
        coverImg: "osakajp.jpg",
        price: "5,42k",
        days: 10,
        stats: {
            rating: 4.8,
        },
    },
]

const travel = [

]

export { trips, events, travel, };