import React from "react";
import ReactDOM from 'react-dom';


export default function Update() {
    return (
      <div className="centered-text">

      </div>
    );
  }
