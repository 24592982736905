import React from "react";
import ReactDOM from 'react-dom';
import Exciting from '../img/exciting.svg'
import SignUp from '../img/signup.svg'
import Worth from '../img/worth.svg'
import Airplane from '../img/airplane.png'

export default function VacationPlans() {
    return (
      <div className="vacation--plans">
        <div className="container">
          <div className="container-title">
            <h1>Things you need <span>to do</span></h1>
            <p>We ensure that you’ll embark on a perfectly planned, safe vacation at a price you can afford.</p>
        </div> 
        <div className="container-title-img">
          <img src={Airplane} className="container-title-img" alt="Plane Img"></img>
        </div>
      </div>


        <div className="vacation--plans--li">
            <div className="column">
            <img src={SignUp} className="vacation--plans--li--img" alt="SignUp Icon"></img>
            <h1>WhatsApp us</h1>
            <p>Send us message on WhatsApp to get best tickets <br />  +39 375 6310363</p>
            </div>
            <div className="column">
            <img src={Worth} className="vacation--plans--li--img" alt="Worth of Money Icon"></img>
            <h1>Get Tickets in Best Price</h1>
            <p>Let us handle the complexities of your air travel arrangements with the Best Fare</p>
            </div>
            <div className="column">
            <img src={Exciting} className="vacation--plans--li--img" alt="Exciting Travel Icon"></img>
            <h1>Exciting Travel</h1>
            <p>Start and explore a wide range of exciting travel experience with <br />  FlyLab Travels</p>
            </div>
        </div>
      </div> 
       
    );
  }