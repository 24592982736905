import React from "react";
import ReactDOM from 'react-dom';
import Star from '../img/star.png';
import Nav from '../img/Navigation.svg';

export default function TripCard(props) {
    return (
      
    <div className="tripcard">
      
   
    
      </div>
       
  );
}
