import React from "react";
import ReactDOM from 'react-dom';
import LogoIMG from '../img/logo f-02.png'

export default function Navbar() {
    return (
    <div>
        <header>
            <div className="navbar--logo">
            <h1><img src={LogoIMG} className="logof--image" alt="logo Image"></img></h1>           
                  </div>
                  <input type="checkbox" id="nav-toggle" className="nav-toggle" ></input>
                  <label for="nav-toggle" className = "nav-toggle-label"> 
                    <span className="label-span"></span> 
                  </label>
                  <nav> 
                    <ul>
                     
                    <li><a> </a></li>
                      <li><a></a></li>
                      <li><a></a></li>  
                      <li><a></a></li> 
                      <li><a></a></li> 
                      
                    </ul>
                  </nav>
                </header>
                  <div class="content">
                  </div>
                </div>

    )
            



}
    