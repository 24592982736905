import React from "react";
import ReactDOM from 'react-dom';
import HeroIMG from '../img/heroimg.png'

export default function Hero() {
    return (
        <div className="hero">
            <img src={HeroIMG} className="hero--image" alt="Hero Image"></img>
            <h1>Get started your <br /> exciting <span>journey</span> <br />with us.</h1>
            <h2>FlyLab Travels is your premier travel agency based in Monza, Italy,  <br /> dedicated to providing  exceptional services for air ticket bookings <br /> from Italy to all over the world </h2>
            <button type="btn" className="btn">Discover Now</button>
        </div>
    )
}